import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Internhub = () => {
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Your Page Title</title>
          <meta
            name="description"
            content="Explore internship opportunities at InternHub, a platform dedicated to connecting students with their dream internships."
          />
        </Helmet>
        <h1 className="text-white">InternHub</h1>
        <a
          href="https://www.internhub.io"
          target="_blank"
          rel="noopener noreferrer"
        >
          Visit InternHub for exciting internship opportunities!
        </a>
      </div>
    </HelmetProvider>
  );
};

export default Internhub;
