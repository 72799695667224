import React from "react";

const Loading = () => {
  return (
    <div>
      <header className="loading-header">
        {/* <p className="line-1 anim-typewriter">hi, my name is aryan patel...</p> */}
        <p className="line-1 anim-typewriter">hi, my name is aryan patel...</p>
      </header>
    </div>
  );
};

export default Loading;