import React, { useState, useEffect } from "react";
// Ensure you import Tailwind CSS in your project entry file
//import all components
// import Nav from "./components/Nav";
import Intro from "./components/Intro";
import About from "./components/About";
import Experience from "./components/Experience";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Loading from "./components/Loading";
import { Routes, Route, useLocation } from "react-router-dom";
import ResumeViewer from "./components/ResumeViewer";
import Internhub from "./components/Internhub";

function App() {
  const [showLoading, setShowLoading] = useState(false);
  const [fadeEffect, setFadeEffect] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setFadeEffect(
      location.pathname !==
        "/resume-2024-fall-aryan-patel-sdfhvjkads83832vbasdgfhjkfhlk" ||
        location.pathname !== "/internhub"
    );
    const timer = setInterval(() => {
      setShowLoading(false);
      // setFadeEffect(true);
    }, 7500);
    return () => clearInterval(timer); // Cleanup the interval on component unmount
  }, [location]);

  return (
    <div
      className={`text-center bg-black text-white font-mono overflow-x-hidden ${
        fadeEffect ? "animate-fadeIn" : "animate-fadeOut"
      } min-h-screen`}
    >
      <Routes>
        <Route
          path="/resume-2024-fall-aryan-patel-sdfhvjkads83832vbasdgfhjkfhlk"
          element={<ResumeViewer />}
        />

        <Route
          path="/"
          element={
            showLoading ? (
              <div className="loading opacity-0 animate-fadeIn">
                <Loading />
              </div>
            ) : (
              <div className="home opacity-100">
                <Intro />
                <About />
                <Experience />
                <Skills />
                <Projects />
                <Contact />
                <Footer />
              </div>
            )
          }
        />
        <Route path="/internhub" element={<Internhub />} />
      </Routes>
    </div>
  );
}

export default App;
