import React from "react";

const ResumeViewer = () => {
  return (
    <div className="h-screen w-full flex justify-center items-center">
      <iframe
        title="Aryan Patel's Resume"
        src="https://drive.google.com/file/d/1kt9p0rWKuOD-tFspYYkvccCPn_ppGafD/preview"
        className="w-full h-full"
        allow="autoplay"
      ></iframe>
    </div>
  );
};

export default ResumeViewer;
